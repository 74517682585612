<template>
  <div class="relation">
    <!-- 工具栏 -->
    <div class="toolbar">
      <el-select v-model="searchType" placeholder="请选择" class="select-type" size="mini">
        <el-option label="按姓名搜索" :value="1"></el-option>
        <el-option label="按文件名搜索" :value="2"></el-option>
      </el-select>
      <el-autocomplete class="input-with-select" v-model="input" :fetch-suggestions="querySearch"
        :placeholder="searchPlaceholder[searchType]" value-key="showString" @select="handleSelect"
        :trigger-on-focus="false" @input="search" size="mini" suffix-icon="el-icon-search">
      </el-autocomplete>
      <el-button size="mini" type="primary" icon="el-icon-connection" @click="getMyRelation()" :disabled="myself">我的图谱
      </el-button>
    </div>
    <!-- 关系图谱 -->
    <div class="echartLayout" v-show="chartData.length > 0 && chartData">
      <div id="container"></div>
      <div id="menu" onMouseLeave="this.style.display = 'none';">
        <ul>
          <!--右键弹出菜单-->
          <li id="menu_blood" @click="searchChart" onMouseOver="this.style.background = '#F5F7FA';"
            onMouseOut="this.style.background = '#fff';">
            <font>展开关系图谱</font>
          </li>
          <li v-if="!selectedItem.isUser" id="menu_influence" @click="common.preview(selectedItem)"
            onMouseOver="this.style.background = '#F5F7FA';" onMouseOut="this.style.background = '#fff';">
            <font>预览</font>
          </li>
          <li v-if="!selectedItem.isUser" id="menu_influence" @click="common.downloadFile(selectedItem)"
            onMouseOver="this.style.background = '#F5F7FA';" onMouseOut="this.style.background = '#fff';">
            <font>下载</font>
          </li>
          <li id="menu_influence" onClick="alert('该功能暂未开放');" onMouseOver="this.style.background = '#F5F7FA';"
            onMouseOut="this.style.background = '#fff';">
            <font>查看详情</font>
          </li>
        </ul>
      </div>

    </div>
    <!-- 无课节-->
    <Empty :show="chartData &&  JSON.stringify(chartData) === '[]'" :text="'暂无资源'"></Empty>
  </div>
</template>

<script>
  import * as echarts from "echarts";
  import women from "@/assets/images/my/women.png";
  import men from "@/assets/images/my/men.png";
  export default {
    props: {
      // 名称
      fileName: {
        type: String
      },
      identifier: {
        type: String
      },
    },
    data() {
      return {
        searchType: 1,
        searchPlaceholder: {
          1: '请输入用户姓名',
          2: '请输入文件名称',
        },
        selectedItem: {}, //当前被选中的人 or文件
        myself: true,
        searchList: [],
        common: Common,
        input: '',
        myChart: null,
        chartData: [],
        chartLink: [],
        coverImg: '',
        contactMap: {
          'mp3': require('@/assets/images/my/contact/mp3.png'),
          'mp4': require('@/assets/images/my/contact/mp4.png'),
          'mov': require('@/assets/images/my/contact/mp4.png'),
          'xsl': require('@/assets/images/my/contact/ex.png'),
          'xslx': require('@/assets/images/my/contact/ex.png'),
          'jpg': require('@/assets/images/my/contact/jpg.png'),
          'jpeg': require('@/assets/images/my/contact/jpg.png'),
          'png': require('@/assets/images/my/contact/jpg.png'),
          'pdf': require('@/assets/images/my/contact/pdf.png'),
          'ppt': require('@/assets/images/my/contact/ppt.png'),
          'pptx': require('@/assets/images/my/contact/ppt.png'),
          'doc': require('@/assets/images/my/contact/word.png'),
          'docx': require('@/assets/images/my/contact/word.png'),
          'other': require('@/assets/images/my/contact/default.png'),
        }
      };
    },
    mounted() {
      if (this.input) return;
      this.getMyRelation()
    },
    watch: {
      fileName: {
        handler(newVal, oldVal) {
          // console.log('fileName', newVal)
          if (!newVal) return;
          this.input = newVal;
          this.selectedItem.showString = newVal;
        },
        immediate: true
      },
      identifier: {
        handler(newVal, oldVal) {
          // console.log('identifier', newVal);
          if (!newVal) return;
          this.selectedItem.identifier = newVal;
          this.searchType = 2;
          this.getFileRelation();
          return;
        },
        immediate: true
      }
    },
    methods: {
      // 搜索栏为空 回到我的图谱
      search(val) {
        // console.log(val);
        if (!val) this.getMyRelation();
      },
      // 动态获取下拉列表
      querySearch(queryString, cb) {
        this.getSearchList().then(res => {
          cb(res);
        });
      },
      // 获取搜索下拉列表数据请求
      async getSearchList() {
        if (!this.input) return;
        let params = {
          type: this.searchType,
          key: this.input
        }
        let resData = await this.$Api.Resources.searchKey(params)
        this.searchList = [];
        this.searchList = resData.data;
        return this.searchList
      },

      // 选中搜索下拉列表项目
      handleSelect(item) {
        // console.log(item);
        this.selectedItem = item;
        this.getFileRelation();
      },

      // 搜索该文件、人物关系图谱
      searchChart() {
        this.getFileRelation();
        let menu = document.getElementById("menu");
        menu.style.display = "block";
      },

      // 按 姓名/文件名 搜索关系
      async getFileRelation() {
        let params = {};
        if (this.searchType == 1) params.userId = this.selectedItem.id;
        if (this.searchType == 2) params = {
          fileName: this.selectedItem.showString,
          identifier: this.selectedItem.identifier
        }
        let resData = await this.$Api.Resources.getFileRelation(params);
        // console.log(resData);
        this.myself = false;
        this.handleNodesSymbol(resData.data);
      },

      // 查询我的关系图谱
      async getMyRelation() {
        let resData = await this.$Api.Home.queryrelation();
        console.log(resData);
        this.myself = true;
        this.searchType = 1;
        this.input = '';
        this.handleNodesSymbol(resData.data);
      },

      // 处理图谱节点数据
      handleNodesSymbol(list) {
        list.nodes.map((item, index) => {
          if (item.ext) {
            item.symbol = this.contactMap[item.ext] ? `image:/${this.contactMap[item.ext]}` :
              `image:/${this.contactMap.other}`;
          } else {
            item.symbol = item.sex == 0 ? `image:/${women}` : `image:/${men}`
          }
        })
        this.chartData = list.nodes;
        this.chartLink = list.relations;
        this.initEchart();
      },

      // 构建图谱
      initEchart() {
        const that = this;
        let dom = document.getElementById("container");
        this.myChart = echarts.init(dom);
        window.addEventListener('resize', () => {
          this.myChart.resize()
        })

        let option = {
          title: {
            text: ""
          },
          tooltip: {
            formatter: function (x) {
              return x.data.des;
            }
          },
          legend: {
            data: [{
              name: '上传',
            }, {
              name: '下载',
            }, {
              name: '预览',
            }]
          },
          series: [{
            type: "graph",
            layout: "force",
            symbolSize: 60,
            roam: true,
            edgeSymbol: ["none", "arrow"],
            edgeSymbolSize: [4, 6],
            edgeLabel: {
              normal: {
                textStyle: {
                  fontSize: 20
                }
              }
            },
            force: {
              initLayout: 'circular',
              repulsion: 750,
              edgeLength: [200, 350],
              friction: 0.2,
            },
            draggable: true,
            itemStyle: {
              normal: {
                color: "#4b565b"
              }
            },
            lineStyle: {
              normal: {
                width: 2,
                color: "#999"
              }
            },
            edgeLabel: {
              normal: {
                show: true,
                formatter: function (x) {
                  return x.data.name;
                }
              }
            },
            label: {
              normal: {
                position: "top",
                show: true,
                distance: 20,
                formatter: function (params) {
                  if (params.name.length > 15) {
                    return `${params.name.slice(0,15)}...`
                  } else return params.name;
                },
              }
            },
            data: this.chartData,
            links: this.chartLink
          }]
        };
        this.myChart.setOption(option);
        this.myChart.on("click", function (params) {
          // console.log(params.data);
          that.selectedItem.showString = params.data.name;
          that.selectedItem.identifier = params.data.url;
          that.selectedItem.id = params.data.nodeId;
          params.data.isUser == 1 ? that.searchType = 1 : that.searchType = 2;

          let menu = document.getElementById("menu");
          menu.style.left = window.event.offsetX + 'px';
          menu.style.top = window.event.offsetY + 'px';
          menu.style.display = "block";
        });
      },
      async checkPreview(identifier) {
        let params = {
          identifier: identifier
        }
        let resData = await this.$Api.Resources.checkPreview(params);
        if (resData.data) {
          let fileToken = this.$store.state.user.fileToken
          window.open(`${this.downloadURL}${identifier}&token=${fileToken}&type=4`)
        } else {
            // console.log('预览正在生成请稍后再试')
          DoUpElement.notify(this, 'warning', "预览正在生成请稍后再试")
        }
      },
    }
  };
</script>

<style lang="less" scoped>
  .relation {
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .toolbar {
      // background-color: #fff;
      border-radius: 8px;
      padding: 20px 20px 8px;
      margin-bottom: 20px;

      .select-type {
        margin-right: 15px;

        /deep/ .el-input__inner {
          border-radius: 25px;
        }
      }

      .el-autocomplete {
        width: 65%;
        margin-right: 20px;

        /deep/ .el-input__inner {
          border-radius: 25px;
        }
      }

      /deep/ .el-button--primary {
        background-color: #508EF9;
        border-color: #508EF9;
        border-radius: 25px;

        &.is-disabled {
          background-color: #a0cfff;
          border-color: #a0cfff;
        }
      }
    }


    .echartLayout {
      position: relative;
      margin: 0 auto;
    }

    #container {
      margin: 0 auto;
      height: 800px;
      width: 1200px;
    }

    #menu {
      position: absolute;
      border-radius: 2px;
      border: 1px solid #E4E7ED;
      background-color: #fff;
      cursor: pointer;
      width: 200px;

      li {
        padding: 0 20px;
        line-height: 34px;
      }
    }

    @media screen and (min-width:1250px) {
      .toolbar {
        padding: 25px 35px 8px;

        .select-type {
          margin-right: 35px;
        }

        .el-autocomplete {
          width: 65%;
          margin-right: 35px;

        }
      }
    }
  }
</style>