<template>
  <div class="resource-center flex-column">
    <!-- <headers></headers> -->
    <div class="resource-center-wrapper manage-wrapper">
      <el-tabs v-model="activeTabName" @tab-click="changeTab">
        <el-tab-pane label="资源中心" name="center">
          <!-- 筛选栏 start -->
          <div class="filter-bar">
            <div class="filter-row">
              <span class="filter-type">项目时间：</span>
              <span class="filter-each csp" :class="[projectTime === item.id ?'select':'']"
                v-for="(item, index) in projectTimeList" :key="index" @click="changeProjectTime(item)">
                {{item.name}}
              </span>
            </div>
          </div>
          <div class="filter-bar">
            <div class="filter-row">
              <span class="filter-type">项目名称：</span>
              <span class="filter-each csp" :class="[projectName === item.id ?'select':'']"
                v-for="(item, index) in projectNameList" :key="index" @click="changeProjectName(item)">
                {{item.name}}
              </span>
            </div>
          </div>
          <div class="filter-bar">
            <div class="filter-row">
              <span class="filter-type">资源类型：</span>
              <span class="filter-each csp" :class="[resourceType === item.id ?'select':'']"
                v-for="(item, index) in resourceTypeList" :key="index" @click="changeResourceType(item)">
                {{item.name}}
              </span>
            </div>
          </div>
          <div class="filter-bar">
            <div class="filter-row">
              <span class="filter-type">格式：</span>
              <span class="filter-each csp" :class="[filterType === item ?'select':'']"
                v-for="(item, index) in formatlist" :key="index" @click="changeFilterType(item)">
                {{item}}
              </span>
            </div>
          </div>
          <!-- 筛选栏 end -->
          <!-- 工具栏 start -->
          <div class="toolbar flex-align-between">
            <el-checkbox v-model="myUploadChecked" @change="getResourcesList"
              @click="myUploadChecked = !myUploadChecked">我的上传</el-checkbox>
            <div class="flex-align">
              <!-- 搜索资源 -->
              <el-input size="mini" placeholder="请输入资源名称/上传者姓名/文件标签" v-model="form.search" suffix-icon="el-icon-search"
                @input="filterSort()" class="search">
              </el-input>
              <!-- 时间排序 -->
              <el-select size="mini" v-model="form.sort" placeholder="排序方式" @change="filterSort()" class="sort-select">
                <el-option :label="item.name" :value="item.id"
                  v-for="(item, index) in [{name:'按上传时间正序',id:0},{name:'按上传时间倒序',id:1}]" :key="index">
                </el-option>
              </el-select>
              <!-- 上传资源 -->
              <div class="uploading csp" @click="openUpload = true">
                <i class="el-icon-upload2"></i>
                上传资源
              </div>

            </div>
          </div>
          <!-- 工具栏 end -->

          <!-- 文件列表 -->
          <div class="file-list-container">
            <!-- file-list start -->
            <ul class="file-list flex-between">
              <li class="file" v-for="(item, index) in resourcesList" :key="index">
                <img :src="downloadURL + item.identifier + '&width=270'" alt=""
                  :onerror="onerrorCoverList[item.ext] ? onerrorCoverList[item.ext] : onerrorUnknowCover"
                  @click="common.preview(item)" class="file-cover csp" />
                <div class="file-info">
                  <p class="file-p file-title wes-2 csp" @click="common.preview(item)">{{ item.fileName }}
                  </p>
                  <p class="file-p file-uploader">上传者：{{ item.name || '未知'}}</p>
                  <div class="flex-align-between">
                    <p class="file-p file-time">
                      上传时间：{{ dayjs(item.createTime).format('YYYY-MM-DD HH:mm') || '--'}}</p>
                    <div class="file-toolbar">
                      <el-tooltip class="item" effect="dark" content="关系图谱" placement="top">
                        <i class="el-icon-connection" @click="openRelation(item)" style="margin-right:8px;"></i>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="下载" placement="top">
                        <i class="el-icon-download" @click="common.downloadFile(item,7)"></i>
                      </el-tooltip>
                    </div>
                  </div>

                </div>
                <div class="btns-more">
                  <el-dropdown trigger="click" class="csp" placement="bottom-end">
                    <i class="el-icon-more"></i>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="openDialog(item,'lable')">添加标签
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="openDialog(item,'edit')">重命名
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="openDialog(item,'share')">分享
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="openDialog(item,'issue')">发布
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="isDelete(item)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </li>
              <!-- 占位 [flex-between布局下当一行不足4个元素时起作用]-->
              <li class="placeholder-file file"></li>
              <li class="placeholder-file file"></li>
            </ul>
            <!-- file-list  end -->

            <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
              :limit.sync="pages.pageSize" :pageSizes="[8,16,24]" @pagination="handlePageChange">
            </pagination>

            <!-- 无课节-->
            <Empty :show="pages.total == 0"></Empty>
          </div>
        </el-tab-pane>
        <el-tab-pane label="关系图谱" name="relation" lazy>
          <ResourceHome :fileName="activeItem.fileName" :identifier="activeItem.identifier">
          </ResourceHome>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <Footer></Footer> -->
    <!-- 重命名Dialog -->
    <el-dialog title="重命名" :visible.sync="editNameDialogVisible" width="50%" center>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="资源名称">
          <el-input v-model="form.reName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editNameDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editName()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分享资源 -->
    <el-dialog title="分享资源" :visible.sync="shareDialogVisible" width="50%" center>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="分享链接">
          <el-input v-model="form.fileUrl">{{downloadURL + activeItem.identifier}}</el-input>
        </el-form-item>
        <el-form-item label="分享有效期">
          <el-select v-model="form.deadline" placeholder="请选择有效期">
            <el-option value="7天">7天</el-option>
            <el-option value="1个月">1个月</el-option>
            <el-option value="永久有效">永久有效</el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="shareDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="shareDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加标签 -->
    <el-dialog title="添加标签" :visible.sync="addLableDialogVisible" width="50%" center>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="添加标签">
          <el-input v-model="form.label"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addLableDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addLableDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 发布资源 -->
    <el-dialog title="发布资源" :visible.sync="issueDialogVisible" width="50%">
      <p style="text-align:center">发布后，该资源将在公共资源库里显示。<br /> 是否确认发布？</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="issueDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="issueDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上传文件 -->
    <UploadAnyFile :show="openUpload" @uploadFile="handleFileData"></UploadAnyFile>
  </div>
</template>

<script>
  import ResourceHome from "./relation.vue";
  import axios from "axios";
  export default {
    components: {
      ResourceHome,
    },
    data() {
      return {
        common: Common, // 公共方法引用
        activeTabName: "center", //当前展示页面名
        openUpload: false, //打开上传资源
        form: {
          // reName: '',
        },
        pages: { //分页
          currentPage: 1,
          pageSize: 8,
          total: 0,
        },
        myUploadChecked: false, //我的上传勾选
        editNameDialogVisible: false, // 显隐重命名
        issueDialogVisible: false, // 显隐发布
        shareDialogVisible: false, // 显隐分享
        addLableDialogVisible: false, // 显隐添加标签

        formatlist: ["全部", "mp3", "mp4", "pdf", "ppt", "pptx", "doc", "docx", "xls", "xlsx", "jpg", ], // 格式list
        filterType: '全部', //当前格式
        projectNameList: [],
        projectTimeList: [{
          name: "全部",
          id: 0
        }, {
          name: "一期",
          id: 83
        }],
        projectTime: 0,
        projectNameList: [],
        projectName: 0,
        resourceTypeList: [{
          name: "全部",
          id: 0
        }, {
          name: "课程视频",
          id: 1
        }, {
          name: "课件",
          id: 2
        }, {
          name: "活动作品",
          id: 3
        }, {
          name: "作业",
          id: 4
        }, {
          name: "项目资料",
          id: 5
        }, {
          name: "其他类型",
          id: 6
        }],
        resourceType: 0,
        projectIdsArr: [], // 用户参与所有项目id
        resourcesList: [], //资源列表
        activeItem: {}, //当前选中的文件
        onerrorCoverList: this.$store.state.resource.defaultResourceCoverMap, //默认封面对照列表
        onerrorUnknowCover: this.$store.state.resource.unknowResourceCover, //统一默认封面
        achievementItem: {
          createTime: "2020-12-17 08:51:28",
          ext: "docx",
          fileName: "赵艺涵老师资料.docx",
          id: 2,
          identifier: "a36d8af71dbc7d62e85e8938720de0a8_106043_docx",
          name: "孙宏慧",
          status: 1,
          type: 1,
          userId: 3113,
        },
      };
    },

    watch: {
      // $route(newVal, oldVal) {
      //   console.log(newVal.query);
      //   if (newVal.query.type === 'report') {
      //     Vue.set(this.form, 'search', "#名校联盟双师课堂小学语文#")
      //     this.getDisplayResources();
      //   } else {
      //     Vue.set(this.form, 'search', "")
      //     this.getResourcesList();
      //   }
      // },
      'form.search'(newVal, oldVal) {
        if (newVal === "#名校联盟双师课堂小学语文#") {
          this.getDisplayResources();
        }
      }
    },
    methods: {
      handleFileData(data) {
        // console.log(data);
        this.openUpload = false;
        this.pages.currentPage = 1
        this.getResourcesList();
      },
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        if (this.$route.query.type === 'report') {
          this.getDisplayResources();
          return;
        }
        this.getResourcesList();
      },
      // 切换头部tab栏
      changeTab(tab) {
        if (tab.name === 'relation') this.activeItem = {}
      },
      // 改变格式
      changeFilterType(name) {
        this.filterType = name
        this.getResourcesList();
      },
      // 查询文件关系图谱
      openRelation(item) {
        this.activeItem = item;
        this.activeTabName = 'relation'
      },
      // 排序
      filterSort() {
        this.pages.currentPage = 1
        this.getResourcesList();
      },
      // 打开对话框
      openDialog(item, type) {
        this.form = {};
        // console.log(item);
        this.form.fileUrl = `${window.location.host}/api/fu/front/upload/download?identifier=${item.identifier}`
        let map = {
          'edit': 'editNameDialogVisible',
          'issue': 'issueDialogVisible',
          'share': 'shareDialogVisible',
          'lable': 'addLableDialogVisible',
        }
        this.activeItem = item;
        this[map[type]] = true;
      },
      // 重命名文件
      async editName() {
        let params = {
          id: this.activeItem.id,
          filename: this.form.reName,
        }
        let resData = await this.$Api.Resources.editName(params);
        if (resData.data.code === 200 && resData.data.data) {
          this.$message.success('修改成功');
          this.editNameDialogVisible = false;
          this.getResourcesList();
        } else {
          this.$message.error(resData.data.msg);
        }
      },
      // 删除资源
      isDelete(item) {
        this.$confirm('是否删除该资源?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$Api.Resources.ResourcesDel(item.id).then((resData) => {
            if (resData.code === 200 && resData.data) {
              this.$message.success('删除成功');
              this.getResourcesList();
            } else {
              this.$message.error(resData.msg);
            }
          })
        }).catch(() => {
          this.$message('已取消删除');
        });
      },

      // 获取资源圈数据
      async getResourcesList() {
        if (this.form.search === "#名校联盟双师课堂小学语文#") return;
        let params = {
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
          status: 1
        }
        // 我的上传
        this.myUploadChecked ? params.userId = this.$store.state.user.userInfo.id : '';
        // 排序方式
        this.form.sort ? params.order = this.form.sort : '';
        // 文件搜索
        this.form.search ? params.fileName = this.form.search : '';
        // 格式搜索
        this.filterType !== '全部' ? params.ext = this.filterType : '';

        let resData = await this.$Api.Resources.getResources(params)
        this.resourcesList = resData.data.list
        this.pages.total = resData.data.totalCount;
      },
      // 获取演示的课程
      async getDisplayResources() {
        let params = {
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
          groupId: 1
        }
        let resData = await this.$Api.Resources.getDisplayResources(params);
        // console.log(resData);
        this.resourcesList = resData.data.list
        this.pages.total = resData.data.totalCount;
      },
      // 获取用户参与的项目
      async getProjectList() {
        let resData = await this.$Api.Home.getProjectList()
        // console.log(resData);
        this.projectNameList = resData.data;
        this.projectNameList.map(item => {
          this.projectIdsArr.push(item.id)
        })
        this.projectNameList.unshift({
          id: 0,
          name: '全部'
        })

        // console.log(this.projectIdsArr);
      },
          // 改变项目时间
      changeProjectTime(timeName) {
        this.projectTime = timeName.id
        this.pages.currentPage = 1
        this.getResourcesList();
      },
      // 改变项目名称
      changeProjectName(name) {
        this.projectName = name.id
        this.pages.currentPage = 1
        this.getResourcesList();
      },
      // 改变资源类型
      changeResourceType(type) {
        this.resourceType = type.id
        this.pages.currentPage = 1
        this.getResourcesList();
      },
      // 初始化 
      init() {
        this.getProjectList()
        if (this.$route.query.achievement === 'achievement') {
          // this.openRelation(this.achievementItem); 
          this.activeTabName = 'relation'
        }

        if (this.$route.query.type === 'report') {
          Vue.set(this.form, 'search', "#名校联盟双师课堂小学语文#")
          this.getDisplayResources();
        } else {
          Vue.set(this.form, 'search', "")
          this.getResourcesList();
        }
      }
    },
    mounted() {
      this.init();
    },
  };
</script>

<style lang="less" scoped>
  .resource-center {
    min-height: 100vh;

    .resource-center-wrapper {
      flex: 1;
      margin-top: 30px;
      box-shadow: 0px 2px 10px 0px #F5F8FA;
      border-radius: 10px;
      padding: 0 25px;
      box-sizing: border-box;
    }

    /deep/ .el-tabs__header {
      margin: 0 !important;
      background-color: #fff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }


    .filter-bar {
      background: #fff;
      padding-left: 32px;
      border-bottom: 1px solid rgba(191, 191, 191, .12);

      .filter-row {
        line-height: 59px;
        font-size: 12px;

        span {
          display: inline-block;
          color: #999;
          margin-right: 50px;
        }

        .filter-type {
          margin-right: 39px;
          color: #555;
        }

        .select {
          color: #508EF9;
        }
      }
    }

    .toolbar {
      background-color: #fff;
      padding: 13px 21px 13px 27px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .search {
        /deep/ .el-input__inner {
          border-radius: 25px;
          width: 300px;
        }
      }


      .sort-select {
        margin-left: 38px;

        /deep/ .el-input__inner {
          border-radius: 25px;
          width: 150px;
        }
      }

      .uploading {
        flex-shrink: 0;

        .el-icon-upload2 {
          margin-right: 11px;
        }

        margin-left: 24px;
        font-size: 12px;
        color: #999;
      }
    }

    .file-list-container {
      margin: 0 auto 50px;
      position: relative;
      padding-top: 40px;

      .file-list {
        flex-wrap: wrap;

        .file {
          width: 23.5%;
          margin-bottom: 28px;
          border-radius: 8px;
          box-sizing: border-box;
          position: relative;
          background-color: #fff;

          .file-cover {
            max-width: 100%;
            width: 100%;
            height: 125px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }

          .file-info {
            padding: 0 10px 15px 10px;

            .file-title {
              margin: 3px 0 8px;
              font-size: 13px;
              font-weight: bold;
              height: 43px;
              overflow: hidden;
            }

            .file-uploader,
            .file-time {
              font-size: 12px;
              color: #999;
            }
          }

          .file-toolbar {
            cursor: pointer;
            text-align: right;

            .el-icon-connection,
            .el-icon-download {
              font-size: 15px;
              color: #aaa;
            }
          }

          .btns-more {
            position: absolute;
            top: 5px;
            right: 10px;

            .el-icon-more {
              font-size: 22px;
              color: #fff;
            }
          }
        }

        .placeholder-file {
          height: 0;
          padding: 0;
          margin: 0 !important;
        }
      }
    }

    @media screen and (min-width:1250px) {
      .resource-center-wrapper {
        margin-top: 36px;
      }

      .filter-bar {
        // margin: 0 0 23px;
        padding-left: 39px;

        .filter-row {
          line-height: 71px;
          font-size: 15px;
        }
      }

      .toolbar {
        padding: 16px 25px 16px 33px;

        .uploading {
          flex-shrink: 0;
          font-size: 15px;
          color: #999;
        }
      }

      .file-list-container {
        .file-list {
          .file {
            margin-bottom: 34px;

            .file-cover {
              height: 151px;
            }

            .file-info {
              padding: 0 15px 20px 10px;

              .file-title {
                margin: 5px 0 12px;
                font-size: 16px;
                height: 50px;
              }

              .file-uploader,
              .file-time {
                font-size: 12px;
              }
            }

            .file-toolbar {

              .el-icon-connection,
              .el-icon-download {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
</style>